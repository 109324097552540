.css-1svfrdm {
    max-width: 100%;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    white-space: nowrap;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    cursor: default;
    outline: 0px;
    text-decoration: none;
    border: 0px;
    padding: 10px;
    vertical-align: middle;
    box-sizing: border-box;
    color: rgb(197, 85, 197);
    background-color: rgba(236, 22, 255, 0.096);
    height: 20px;
    font-size: 0.875rem !important;
    font-weight: 600;
    border-radius: 5px;
    text-transform: capitalize;
}

.css-7etjdk {
    margin-right: 2rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.css-qwjwsb {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    font-size: 1.25rem !important;
    line-height: 1;
    overflow: hidden;
    user-select: none;
    border-radius: 5px;
    color: rgb(255, 180, 0);
    background-color: rgba(255, 180, 0, 0.12);
    margin-right: 0.75rem;
}

.css-4vawlq {
    margin: 0px;
    font-weight: 500;
    font-size: 1.5rem !important;
    letter-spacing: 0.15px;
    color: rgba(58, 53, 65, 0.87);
    line-height: 1 !important;
}

.css-4yvesp {
    margin: 0px;
    font-weight: 400;
    font-size: 0.875rem !important;
    line-height: 1.5;
    letter-spacing: 0.15px;
    color: rgba(58, 53, 65, 0.68);
}

.content {
    padding: 2px;
}

.home .css-bgtkw1-MuiAvatarGroup-root .MuiAvatar-root {
    width: 34px;
    height: 34px;
}

/* width */
.home ::-webkit-scrollbar {
    width: 5px;
}

.home .MuiCircularProgress-root {
    width: 55px !important;
    height: 55px !important;
}

.home .profile img {
    display: block;
    max-width: 250px;
    max-height: 95px;
    width: 80%;
    height: 100%;
    border-radius: 10px;
}

.home .fixed-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding-left: 5px;
    z-index: 999;
    padding: 16px;
    padding-bottom: 3px;
    padding-top: 11px;
}

.home .MuiCard-root {
    box-shadow: 0 0 10px 0 #86868826 !important;
    border: 1px solid #e9e5e5;
    border-radius: 25px;
}

.home .awards {
    font-weight: 600;
    rotate: calc(350deg);
    font-size: 18px;
    margin-top: 30px;
    border-top: 1px dashed lightgray;
    border-bottom: 1px dashed lightgray;
    border-width: 4px;
    color: gray;
}
.background_img{
    background-image: url(../images/profile-banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    block-size: 200px;
}
.Profile{
    border-start-start-radius:6px ;
    border-start-end-radius:6px ;
    margin-block-start: -45px;
    background-color: #fff;
    border-color: #fff;
    border-width: 5px;

}
.office_logo{
  border-radius:6px ;
    background-color: #fff;
    border-color: #fff;
    border-width: 5px;
    margin: 10px;

}

.folder {
   
    border-radius: 25px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .folder-title {
    color: "#212B36";
    font-weight: 800;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .quick-app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 35px;
    color: rgba(46, 38, 61, 0.7);
    background-color: #fbfaff !important;
    cursor: pointer;
    transition: background-color 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  }
  
  .quick-app:hover {
    background-color: #555;
    transform: scale(1.05);
  }
  
  .quick-app-icon {
    font-size: 32px;
  }
  
  .quick-app-name {
    text-align: center;
    font-size: 14px;
  }