.navbar-burger {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0.5rem;
}

.navbar-burger span {
  display: block;
  height: 2px;
  width: 20px;
  background-color: #333;
  margin: 4px 0;
  transition: transform 0.2s;
}

.navbar-burger.is-active span:first-child {
  transform: translateY(6px) rotate(45deg);
}

.navbar-burger.is-active span:nth-child(2) {
  opacity: 0;
}

.navbar-burger.is-active span:last-child {
  transform: translateY(-6px) rotate(-45deg);
}

.dropdowns {
  position: static;
  flex-direction: row;
  justify-content: flex-start;
  background: transparent;
  opacity: 1;
  visibility: visible;
}
.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dropdown:hover + .dropdown-menu {
  opacity: 1;
  visibility: visible;
  translate: 0 0;
}

.dropdown-menu {
  display: grid;
  margin-bottom: 28px;
}
.dropdown {
  height: 100%;
  flex-direction: row;
}

.dropdown-menu {
  position: absolute;
  top: 70px;
  left: 0;
  width: 250px;
  padding: 6px 24px 10px;
  margin-bottom: 0;
  place-items: start;
  background: #8b44ff;
  color: #ffffff;
  opacity: 0;
  visibility: hidden;
  translate: 0 24px;
  transition: 0.3s;
}

.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  translate: 0 0;
}
.dropdown-menu a {
  color: #ffffff;
  padding: 10px 10px 10px 0px;
  font-size: 15px;
  font-weight: 500;
}
.dropdown-menu a:hover {
  cursor: pointer;
}
.link {
  position: relative;
}

.link:after {
  content: "";
  position: absolute;
  width: 0;
  height: 3px;
  display: block;
  margin-top: 5px;
  right: 0;
  background: #fecb03;
  transition: width 0.2s ease;
  -webkit-transition: width 0.2s ease;
}

.link:hover:after {
  width: 100%;
  left: 0;
  background: #fecb03;
}

@media screen and (max-width: 991px) {
  .navbar-burger {
    display: block;
  }
  .navbar-burger.is-active {
    border-radius: 10px;
    background-color: #fecb03;
    color: #000;
  }

  .nav-menu {
    opacity: 1 !important;
  }
  .nav-menu.is-active {
    display: block !important;
    opacity: 1 !important;
    animation: fadeIn 0.5s ease-in-out forwards;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .drop_sec {
    position: absolute;
    /* overflow: hidden; */
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
}
@media screen and (min-width: 992px) {
  .drop_sec {
    position: none;
    overflow: none;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
  .navbar-burger {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 1200px) {
}
