/* width */
.ats-settings ::-webkit-scrollbar {
    width: 5px;
}

.ats-settings .shadow {
    -moz-box-shadow: 5px 5px 4px 0px #dddada;
    -webkit-box-shadow: 5px 5px 4px 0px #dddada;
    box-shadow: 5px 5px 4px 0px #dddada;
}

.card-title-dashboard {
    background-image: linear-gradient(to right, #8b44ff, #caa8ff);
    color: white;
}

.truncate-heading {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.offer_customization .quill-container{
    width: 100%;
}
.offer_customization .quill-container .quill .ql-snow{
    background-color: #fff;
}

/* src/A4Sheet.css */
.a4-sheet {
    width: 170mm;
    /* height: 297mm; */
    margin: 0 auto;
    padding: 10mm;
    box-shadow: -10px 0px 13px -7px #09090930, 10px 0px 13px -7px #09090930, 5px 5px 15px 5px rgba(0,0,0,0);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
  }
  
  .a4-header, .a4-footer {
    display: flex;
    align-items: center;
    padding: 2mm 0;
    justify-content: center;
    text-align: center;
  }
  
  .a4-header {
    border-bottom: 1px solid #ccc;
  }
  
  .a4-footer {
    border-top: 1px solid #ccc;
  }
  
  .company_logo {
    width: 80px;
    height: auto;
  }
  
  .header-text {
    flex-grow: 1;
    text-align: center;
    font-size: 14pt;
    font-weight: bold;
  }
  
  .a4-body {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    font-size: 12pt;
    padding: 5mm;
  }
  