.workflow-title-wrapper-down {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 5rem;
    grid-row-gap: 4rem;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  @media screen and (max-width: 991px) {
    .workflow-title-wrapper-down {
      grid-row-gap: 2.5rem;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }
  }