/* Outside Border */
.fc-scrollgrid {
  border: none !important;
}

.fc-scrollgrid td:last-of-type {
  border-right: none !important;
}

/* Current date Background Color */
.fc-day-today {
  background: #ffffff !important;
  border: none !important;
}

.fc-h-event .fc-event-main {
  font-size: 14px;
}

.upload_doc {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(145, 158, 171);
  cursor: pointer;
  width: max-content;
}

.dropzone {
  min-height: 0px;
}

.card-icon {
  right: 7px;
  bottom: 0px;
  height: 130px;
  position: absolute;
}

.mpl-primary-btn .ats-csv-btn {
  color: #883bd5 !important;
}

.mpl-primary-btn .ats-csv-btn :hover {
  color: #883bd5 !important;
}

.progress-value {
  animation: load 3s normal forwards;
  border-radius: 100px;
  background: #fff;
  height: 8px;
  width: 100%;
  margin-top: 10px;
}

.my-bar-chart .apexcharts-toolbar {
  top: -25px !important;
  right: 3px !important;
}
.assigne_section {
  padding: 10px;
  width: 500px;
}
.invoicesection table tbody tr td div {
  border-bottom: 1px solid grey !important;
  cursor: pointer;
  width: 100% !important;
}
.cta {
  border: none;
  background: none;
  cursor: pointer;
}

.cta span {
  padding-bottom: 7px;
  letter-spacing: 4px;
  font-size: 11px;
  color: #883bd5;
  padding-right: 15px;
  text-transform: uppercase;
}

.cta svg {
  transform: translateX(-8px);
  transition: all 0.3s ease;
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active svg {
  transform: scale(0.9);
}
.cta-disable {
  border: none;
  background: none;
  cursor: pointer;
}

.cta-disable span {
  padding-bottom: 7px;
  letter-spacing: 4px;
  font-size: 11px;
  color: lightgray;
  padding-right: 15px;
  text-transform: uppercase;
}

.cta-disable svg {
  transform: translateX(-8px);
  transition: all 0.3s ease;
}

.cta-disable:hover svg {
  transform: translateX(0);
}

.cta-disable:active svg {
  transform: scale(0.9);
}

#scroll-box-icon-container {
  position: absolute;
  width: 100px;
  bottom: 75px;
  right: 25px;
  display: flex;
  gap: 4px;
  background-color: #ffffff;
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

#scroll-box-icon {
  border-radius: 4px;
  cursor: pointer;
  width: 12px;
  height: 30px;
  background-color: #f0f1f2;
  border: 1px solid #ccc;
}
#arrow-back-button {
  position: relative;
  bottom: 25px;
  left: 12px;
}
.response-main {
  /* margin-top: 44px; */
  margin-left: auto;
  margin-right: auto;
  text-size-adjust: none;
  background: rgb(255, 255, 255);
  max-width: 850px;
  user-select: auto;
}
.response-content {
  border-top: 5px solid #f9c307;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 11px;
}
.Form_head {
  background: #883bd5;
  color: #fff;
  padding: 1rem 0.75rem 3.125rem 3.125rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.form_content {
  padding: 1.25rem 1.875rem 1.375rem;
  background: #f6edfff3;
}
.form_content_btn {
  padding: 1.25rem 3.875rem 1.375rem;
  background: #f6edfff3;
}
.bg_form_main {
  background: rgb(249, 195, 7);
  background: radial-gradient(
    circle,
    rgba(249, 195, 7, 0.44038865546218486) 0%,
    rgba(136, 59, 213, 0.513217787114846) 100%
  );
}
.button-11 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 8px;
  border-radius: 6px;
  color: #3d3d3d;
  background: #fff;
  border: none;
  box-shadow: 0px 0.5px 1px #883bd5;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-left: 5px;
}

.button-11:focus {
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #883bd5;
  outline: 0;
}
@keyframes load {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .dropzone {
    min-height: 50px;
  }
  .my-donut-chart .apexcharts-toolbar {
    right: -110px !important;
  }
}

#lens-icon-div {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  cursor: all-scroll;
  border: 2px solid #883bd5;
  z-index: 2000;
}
#droppable-div {
  height: 76vh;
}

/* For screens smaller than 600px */
@media (max-width: 600px) {
  #droppable-div {
    height: 80vh;
  }
}

/* For screens between 601px and 960px */
@media (min-width: 601px) and (max-width: 960px) {
  #droppable-div {
    height: 80vh;
  }
}

/* For screens between 961px and 1280px */
@media (min-width: 961px) and (max-width: 1280px) {
  #droppable-div {
    height: 76vh;
  }
}
@media (min-width: 1281px) {
  #droppable-div {
    height: 82vh;
  }
}
