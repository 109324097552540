.ql-toolbar.ql-snow {
    font-family: 'Nunito';
    width: 100%;
    /* Border radius for the top-left corner */
    border-top-left-radius: 10px;
    /* Border radius for the top-right corner */
    border-top-right-radius: 10px;
}

.ql-snow {
    box-sizing: border-box;
    width: 100%;
    font-family: 'Nunito';
}

.ql-container {
    box-sizing: border-box;
    width: 100%;
    overflow: auto;
    /* Border radius for the bottom-left corner */
    border-bottom-left-radius: 10px;
    /* Border radius for the bottom-right corner */
    border-bottom-right-radius: 10px;
}

.quill-container.hide-border .ql-toolbar.ql-snow,
.quill-container.hide-border .ql-snow {
    border: none;
}

.quill-container.show-border .ql-toolbar.ql-snow,
.quill-container.show-border .ql-snow {
    box-sizing: border-box;
    border: 1px solid #ccc;
}
.mention-dropdown {
    position: absolute;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    width: 250px; /* Adjust width as needed */
}

.mention-dropdown li {
    padding: 8px 12px;
    cursor: pointer;
    font-size: 14px;
    color: #333;
    transition: background 0.3s, color 0.3s;
}

.mention-dropdown li:hover {
    background: #f0f0f0;
    color: #007bff;
}

.mention {
    background-color: #d1ecf1;
    color: #0c5460;
    padding: 2px 4px;
    border-radius: 3px;
}
