.react-datepicker {
  padding: 5px !important;
  background-color: #ffff !important; 
  z-index: 10 !important; 
  
}
.react-datepicker__input-container input {
    background-color: #ffff; 
    padding: 10px;             
    font-size: 14px;
    color: #333; 
  }

.react-datepicker__header {
  border-bottom: 1px solid #ddd; 
  background-color: #ffff !important; 
}

/* Individual time item styling */
.react-datepicker__time-list-item {
    padding: 5px 10px;
    cursor: pointer;
    background-color: #ffffff;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}
/* Selected time item */
.react-datepicker__time-list-item--selected {
  background-color: #f9c307 !important;
  color: black !important;
}

.react-datepicker__day--selected {
  background-color: #f9c307 !important;
  color: black !important;
  border-radius: 50% !important; 
  padding: "2px" !important;
}

/* Hover effect for time items */
.react-datepicker__time-list-item:hover {
  background-color: #c6c9c6;
}
.react-datepicker__day:hover {
  background-color: #f0f0f0;
  color: #000;
  border-radius: 50%;
}

  /* Hover effect for day items */
.react-datepicker__day {
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 50%;
  }
