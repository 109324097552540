.dropzone {
    min-height: 0px;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0rem;
    border-radius: 6px;
    border: 2px dashed rgba(93, 89, 98, 0.22);
}

.leave-ledger .MTableToolbar-root-5 {
    min-height: 35px !important;
}

.css-1ogz6cm-MuiPaper-root {
    box-shadow: 0px -0.3px 0px 0px rgb(0 0 0 / 20%), 0px 0.5px 0px 0.5px rgb(0 0 0 / 14%), 0px 0.5px 0.5px -0.5px rgb(0 0 0 / 12%) !important;
}

.leave-reason-dialog .css-1mcngue-MuiModal-root-MuiDialog-root .MuiDialog-paper {
    width: 35% !important;
}

.chipdesign {
    border-radius: 16px !important;
    white-space: nowrap !important;
    vertical-align: middle !important;
    box-sizing: border-box !important;
    height: 24px !important;
    font-size: 0.75rem !important;
    /* text-transform: capitalize !important; */
    font-weight: 600;
}
.centered-text-input input {
    text-align: center !important; /* Use !important to ensure the style is applied */
}