.canditate-des {
    position: relative;
    display: inline-block;
    border: 2px solid #cdc9cd;
    outline-offset: 3px;
    border-radius: 100%;
    width: 120px;
    height: 120px;
    margin-left: 24%;
}

.canditate-des .upload-link {
    position: absolute;
    width: 25px;
    height: 25px;
    line-height: 36px;
    background: #fff;
    bottom: 0 !important;
    right: 0;
    box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
    border-radius: 100%;
    color: #2E55FA;
    overflow: hidden;
}

.update-flie {
    position: absolute;
    opacity: 0;
    z-index: 0;
    width: 100px;
    left: 0;
}

.css-1xg4v5q-MuiPaper-root {
    box-shadow: 0px -0.3px 0px 0px rgb(0 0 0 / 20%), 0px 0.5px 0px 0.5px rgb(0 0 0 / 14%), 0px 0.5px 0.5px -0.5px rgb(0 0 0 / 12%) !important;
}