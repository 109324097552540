/* width */
.onboarding-settings ::-webkit-scrollbar {
    width: 5px;
}

.onboarding-settings .shadow {
    -moz-box-shadow: 5px 5px 4px 0px #dddada;
    -webkit-box-shadow: 5px 5px 4px 0px #dddada;
    box-shadow: 5px 5px 4px 0px #dddada;
}

.card-title-dashboard {
    background-image: linear-gradient(to right, #76548B, #A9739E);
    color: white;
}