
  .join_meet{
    background-color:#8b44ff;
    padding: 10px 25px;
    border: none;
    border-radius: 4px;
    margin: 0px;
    color: #fff;
  }
  .delete_meet{
    background-color:#ff4444;
    padding: 10px 25px;
    border: none;
    border-radius: 4px;
    margin: 0px;
    color: #fff;
  }
  .google-btn {
    display: flex;
    align-items: center;
    background-color: white;
    color: #757575;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .google-btn:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .google-logo {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  
  .btn-text {
    font-family: Arial, sans-serif;
  }
  