.css-1xtapnb-MuiTableCell-root {
    padding: 10px 10px 10px 10px !important;
}

.role-access .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 20px !important;
    padding: 8.5px 14px !important;
}

.css-10botns-MuiInputBase-input-MuiFilledInput-input {
    font-size: 20px !important;
    padding: 8.5px 14px !important;
}