/* allnotification.css */

@keyframes scroll-left {
    0% {
      transform: translateX(3%); /* Start off-screen to the right */
    }
    80% {
      transform: translateX(-80%); /* End off-screen to the left */
    }
  }

  .notification-container {
    max-width: 450px;
    min-width: 350px;
    max-height: 250px;
    min-height: 33vh;
    overflow-y: auto;
    padding: 10px;
  }
  .menu-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    overflow: hidden;
  }

  .scrolling-text-wrapper {
    display: flex;
    align-items: center;
    width: 95%;
    padding: 10px 0px;
  }
  .scrolling-text {
    display: inline-block;
    white-space: nowrap;
    min-width: 100%;
    padding: 10px 0px;
  }
  
  /* Hover on MenuItem triggers the scroll animation */
   .scrolling-text:hover {
    animation: scroll-left 10s linear forwards;
  }
  