.workflow-title-wrapper {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  padding-top: 150px;
}
.contact_img img {
  width: 100%;
}
.contact_us_input{
    margin-bottom: 30px !important;
}
.workflow-content-right textarea{
    height: 300px !important;
}
.contact_msg{
  
  height: 300px !important;
}
.workflow-content-right .text-field .MuiInputBase-root{
  border-radius: 16px ;
}
.workflow-content-right .text-field .MuiInputBase-root .MuiOutlinedInput-notchedOutline{
  bottom: -5px;
}
.footer-btn{
  margin-top: 30px;
}
.error-msg{
  color: #d32f2f;
  position: absolute;
  bottom: 95px;
}
@media screen and (max-width: 991px) {
  .workflow-title-wrapper {
    grid-row-gap: 2.5rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}
