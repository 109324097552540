.presentation {
    width: 144px;
    height: 144px;
    margin: auto;
    display: flex;
    cursor: pointer;
    overflow: hidden;
    border-radius: 50%;
    align-items: center;
    position: relative;
    justify-content: center;
    border: 1px dashed rgba(145, 158, 171, 0.32);
}

.offer_presentation {
    width: 100px;
    height: 100px;
    margin: auto;
    display: flex;
    cursor: pointer;
    overflow: hidden;
    border-radius: 50%;
    align-items: center;
    position: relative;
    justify-content: center;
    border: 1px dashed rgba(145, 158, 171, 0.32);
}
.placeholder {
    z-index: 7;
    display: flex;
    border-radius: 50%;
    position: absolute;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    color: rgb(145, 158, 171);
    background-color: rgb(244, 246, 248);
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.content-sec {
    margin: 16px auto 0px;
    line-height: 1.5;
    font-size: 0.75rem;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    color: rgb(99, 115, 129);
    display: block;
    text-align: center;
}